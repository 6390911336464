@import '../variables.scss';

svg {
    fill: currentColor;
}

.icon--dots {
    display: flex;
}

.icon--tooltip {
    position: relative;
    top: 3px;
}

.status-icon {
    content: ' ';
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $haze-grey;

    &.status--1 {
        background-color: $yellow;
    }
    &.status--2 {
        background-color: $orange;
    }
    &.status--3 {
        background-color: $blue;
    }
    &.status--4 {
        background-color: $green;
    }
    &.status--5 {
        background-color: $slate-grey;
    }
    &.status--6 {
        background-color: $snail;
    }
}

.loading-spinner {
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-name: rotate-forever;
    animation-timing-function: linear;
    height: 17px;
    width: 17px;
    border: 2px solid $white;
    border-right-color: transparent;
    border-radius: 50%;
    display: inline-block;
    position: relative;
	top: 2px;
}

.form-add {
    .icon {
       	position: relative;
       	// top: 3px;
       	margin-right: 7.5px;
    }
}

.icon--expand,
.icon--collapse,
.icon--reorder {
    [data-class="icon-stroke"] {
        fill: $purple;
    }
    [data-class="icon-bg"] {
        fill: $white;
    }
    &:hover {
        [data-class="icon-stroke"] {
            fill: $white;
        }

        [data-class="icon-bg"] {
            fill: $purple;
        }
    }
}

.icon--chevron-right-24 {
    width: 24px;
    height: 24px;
}