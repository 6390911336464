@import './variables.scss';

$episode-day-width: 15%;
$episode-period-width: 40%;
$episode-panels-width: 25%;
$episode-urgent-width: 15%;
$episode-actions-width: 5%;

.review {
    .btn-container .btn {
        padding-left: 16px;
        padding-right: 16px;
    }
    .btn--save-exit {
        min-width: 200px;
    }
    .panel-group,
    .episode-group,
    .analyte-group {
        padding-top: 8px;
        padding-bottom: 8px;
        &:not(:last-child) {
            border-bottom: 1px solid $haze-grey;
        }
        .list--item {
            display: flex;
        }
    }
    .panel-group {
        &:not(:last-child) {
            border-color: #e8e8e8;
        }
    }
    .list--heading {
        padding-bottom: 8px;
        margin-bottom: 16px;
        border-bottom: 2px solid $haze-grey;
    }
    &__error-highlight {
        color: $red;
        font-weight: bold;
    }
}

.review-panel,
.review-section {
    h3 {
        font-size: 21px;
    }
}

.review-section {
    h3 {
        margin-bottom: 16px;
    }
    &:not(:last-child) {
        border-bottom: 1px solid $haze-grey;
        margin-top: 24px;
        margin-bottom: 24px;
        /* padding-top: 24px; */
        padding-bottom: 24px;
    }
    
    &:last-child {
        border: 0 none;
        padding-top: 0;
    }
}

.review-panel {
    // padding: 20px;
    border-radius: $border-radius;
    background-color: $white-3;
    &:not(:first-child) {
        margin-top: 16px;
    }
    &:not(:last-child) {
        margin-bottom: 16px;
    }
    h3 {
        margin-bottom: 0;
    }
}

.review-item {
    &:not(:last-child) {
        margin-bottom: 8px;
    }
    .review-heading {
        font-weight: bold;
    }
}

/* Protocol overview */

/* Screening */
.review__screening {
    .item__panel {
        width: 100%;
    }
}

/* Cohorts */
.review__cohorts {
    .cohort__details {
        margin-bottom: 16px;
    }
    .cohort:not(:first-child) .cohort__details {
        margin-top: 16px;
    }
    .cohort__heading {
        color: $purple;
    }
    .accordion > .accordion__content {
        padding-bottom: 0;
    }
    .item__day {
        width: $episode-day-width;
    }
    .item__period {
        width: $episode-period-width;
    }
    .item__panels {
        width: $episode-panels-width;
    }
    .item__urgent {
        width: $episode-urgent-width;
    }
    .item__actions {
        width: $episode-actions-width;
    }

    .accordion--episodes {
        &.is-expanded {
            background-color: $white-2;
        }
    }
}

.review__episodes {
    > .list--heading {
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 0;
    }
    .episode-group {
        padding-left: 16px;
        padding-right: 16px;
        border-bottom: 1px solid $haze-grey;
        &.is-expanded,
        &:hover {
            background-color: $silvera;
        }
        .list--item {
            display: flex;
            cursor: pointer;
        }
        .accordion-toggle {
            height: 16px;
            width: 16px;
        }
        .accordion__content {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .item__actions {
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        svg {
            width: 16px;
            height: 16px;
        }
    }
}

.review__episode-panels {
    padding-top: 8px;
    margin-left: $episode-day-width;
    width: auto;
    .item__panel {
        width: 100%;
    }
}

/* Protocol status */
.review__protocol-status {
    p {
        margin-bottom: 8px;
    }
    .btn--change-status {
        min-width: 200px;
    }
}

.status__current-status {
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: bold;
    .status-icon {
        width: 16px;
        height: 16px;
        position: relative;
        top: 2px;
        margin-right: 10px;
    }
}

.status__options {
    .form-radio-label {
        font-weight: bold;
    }
    .status-icon {
        margin-right: 8px;
    }
    ul {
        margin-bottom: 0;
    }
    li:not(:last-child) {
        margin-bottom: 12px;
    }
}

/* Quote */
.review__quote {
    h3 {
        display: flex;
        align-items: center;
        .icon--info {
            margin-right: 12px;
        }
        .heading {
            position: relative;
            top: -2px;
        }
    }
    .btn-container {
        padding: 0;
        margin: 0;
        border: 0 none;
        .btn + .btn {
            margin-left: 24px;
        }
        .btn--download-quote {
            min-width: 200px;
            // padding-left: 44px;
            // padding-right: 44px;
        }
        .btn--download-protocol-summary {
            min-width: 255px;
            // padding-left: 28px;
            // padding-right: 28px;
        }
    }
}

.modal--download-not-ready {
    .modal-footer {
        justify-content: flex-end;
    }
}