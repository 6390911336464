@import './variables.scss';

$page-width: 210mm;
$page-height: 296mm;

$page-horizontal-margin: 5mm;
$page-vertical-margin: 13mm;

$label-horizontal-spacing: 0.1175cm;
$label-vertical-spacing: 0;
$label-height: 33.8mm;
$label-width: 99.1mm;
$label-padding: 3mm;
$label-border-radius: 4mm;
$label-border: 1px solid $haze-grey;

$label-title-width: 30mm;
$label-value-width: 30mm;

.kits-management {
    &.hide {
        display: none;
    }
}

/* Screen only */
@media screen {
    .kit-print {
        .kit-labels__container {
            justify-content: space-around;
        }

        .kit-label {
            width: 48%;
        }
    }
}

/* Print only */
@media print {

    header,
    nav,
    .page-heading {
        display: none !important;
    }

    .kit-print {
        .kit-print__back {
            display: none !important;
        }

        .kit-labels__container {
            &:after {
                content: "";
                display: table;
                clear: both;
            }
        }

        .kit-label {
            width: 48%;
        }
    }
    .container {
        width: 100%;
        max-width: 100%;
        margin: 0;
        padding: 0;
    }
    .flex-table.kit-label__details.container {
        min-width: unset !important;
    }
    .row {
        display: block;
        margin: 0;
        padding: 0;
    }
    .selected-filters {
        padding: 23px !important;
    }

    .a4-page-landscape {
        width: $page-width;
        height: $page-height;
        padding: $page-vertical-margin $page-horizontal-margin;
    }
    .a4-page-portrait {
        height: $page-width;
        width: $page-height;
        padding: $page-vertical-margin $page-horizontal-margin;
    }

    @page {
        size: $page-height $page-width;
    }

    .print_label {
        height: $label-height;
        width: $label-width;
        padding: $label-padding;
        border-radius: $label-border-radius;
        border: $label-border;
        float: left;

        &:nth-child(odd) {
            margin-right: $label-horizontal-spacing;
        }

        &:nth-child(even) {
            margin-left: $label-horizontal-spacing;
            margin-bottom: 3px;
        }

        .row {
            display: block;
            clear: both;

            &:after {
                content: "";
                display: table;
                clear: both;
            }
        }

        .kit-label__title {
            width: $label-title-width;
            float: left;
        }

        .kit-label__value {
            width: $label-value-width;
            float: left;
        }
    }

}