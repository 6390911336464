@import './variables.scss';

.protocol-list {
    .item__no {
        width: 11%;
        @media (min-width: $screen-xlg) {
            width: 15%;
        }
    }

    .item__name {
        width: 33%;
        @media (min-width: $screen-xlg) {
            width: 33%;
        }
    }

    .item__status {
        width: 33%;
        @media (min-width: $screen-xlg) {
            width: 27%;
        }
    }

    .item__actions {
        width: $btn-action-size + $half-gutter;
        display: flex;
        justify-content: flex-end;
    }
}
.status-icon {
    margin-right: 8px;
}

.btn--add-protocol {
    padding-left: 20px;
    padding-right: 20px;
}

.add-edit-protocol {
    .btn-container {
        padding-bottom: 24px;
    }
}