@import './variables.scss';

$icon-size: 24px;
$horizontal-padding: 20px;

.accordion__heading {
    padding: 12px $horizontal-padding;

    .accordion-toggle__container {
        display: flex;
    }
    h3 {
        display: inline-block;
    }

    .btn {
        min-width: 88px;
    }
}

.accordion-toggle {
    display: flex;
    cursor: pointer;
    position: relative;
    &:not(.is-expanded):hover,
    &.is-expanded {
        .icon {
            [data-class="icon-stroke"] {
                fill: $white;
            }
            [data-class="icon-bg"] {
                fill: $purple;
            }
        }
    }
    &.is-expanded:hover {
        .icon {
            [data-class="icon-stroke"] {
                fill: $purple;
            }
            [data-class="icon-bg"] {
                fill: $white;
            }
        }
    }
    &:hover {
        h3,
        h4 {
            text-decoration: underline;
        }
    }
    .icon {
        display: inline-block;
        width: $icon-size;
        height: $icon-size;
        margin-right: 10px;
    }
}

.accordion__content {
    padding: 16px $horizontal-padding;
    &.hide {
        display: none;
    }
}

.accordion--episodes {
    margin-left: -$horizontal-padding;
    margin-right: -$horizontal-padding;
    border-bottom: 1px solid $haze-grey;
    h4 {
        margin-bottom: 0;
    }
}