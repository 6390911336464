@import './variables.scss';

.selected-filters {
    width: 100%;
    border-radius: $border-radius;
    border: 1px solid $haze-grey;
    padding: 16px;

    .list--item {
        &:not(:first-child) {
            margin-top: 4px;
        }
        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }

    .filter-label__title {
        width: 50%;
    }
    
    .filter-label__value {
        width: 50%;
    }
}
.table-cell{
    width: 100%;
    border-radius: $border-radius;
    border: 1px solid $haze-grey;
}
.printing_title{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
}
.date-printed{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
}
.table-header{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;

}
.kit-label__details {
    font-size: 13px;
}
