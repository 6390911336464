@import './variables.scss';

.number-incrementer {
    padding: 0;
    width: auto;
    display: inline-flex;
    input {
        text-align: center;
        min-width: 95px;
        padding-left: 0.375rem;
        padding-right: 0.375rem;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0;
            /* <-- Apparently some margin are still there even though it's hidden */
        }
    }
    button {
        width: $input-height;
        height: 100%;
        color: $purple;
        background: none;
        cursor: pointer;
        display: inline-flex;
        &:hover,
        &:focus {
            &:not(:disabled) {
                background-color: $purple;
                color: $white;
                [data-class="icon-stroke"] {
                    fill: $white;
                }
            }
        }
        &:focus {
            outline: none;
        }
        &:disabled {
            opacity: 0.5;
            cursor: auto;
        }
        .icon {
            width: 24px;
            height: 24px;
        }
        [data-class="icon-stroke"] {
            fill: $purple;
        }
    }
    .number__decrement {
        border-right: 1px solid $haze-grey;
    }
    .number__increment {
        border-left: 1px solid $haze-grey;
    }
    // Hide Spinner in Input Number - latest Firefox
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    input[type=number] {
    -moz-appearance:textfield;
    }
}