.popover__container {
    position: relative;
}

.popover {
    font-family: $font;
    font-size: 1rem;
    box-shadow: 1px 1px 2px 1px $haze-grey;
    &.show {
        display: block;
    }
}

.popover-header {
    font-size: 18px;
    color: $purple;
    padding: 12px 20px;
}

.popover-body {
    padding: 16px 20px 24px;
}

.bs-popover-left .arrow,
.bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 15px;
    height: 20px;
    margin: auto;
    top: 0;
    bottom: 0;
}

.popover--cant-find-panel {
    max-width: unset;
    width: 550px;
    top: -185px;
    bottom: auto;
    left: auto;
    right: 170px;
    background: $white;
    z-index: 20;
    display: none;
    ul {
        padding-left: 16px;
    }
    p,
    li {
        margin-bottom: 12px;
    }
    a {
        text-decoration: underline;
        color: inherit;
    }
}