@import './variables.scss';

header {
    padding-top: 24px;
    padding-bottom: 24px;
    background-color: $purple;
    background-image: url('./../assets/images/header-graphic.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: $white;
    a,
    a:hover,
    a:focus {
        color: inherit;
    }
    a:hover,
    a:focus {
        color: inherit;
        text-decoration: underline;
    }
    .user{
        width: 82px;
        height: 24px;
        color:#dcc0ff;
        margin-left: 7px;
        margin-right: 22px;
    }
}