@font-face {
  font-family: 'CircularStd';
  src: url('./../assets/fonts/CircularStd-Book.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd';
  src: url('./../assets/fonts/CircularStd-Bold.otf');
  font-weight: bold;
  font-style: normal;
}

// Colours
$purple: #753bbd;
$purple-lighten-1: #7d55c7;
$purple-lighten-2: #beaae3;
$purple-opacity-40: rgba(#ede8fa, 0.4);


$dark-grey: #222222;
$slate-grey: #3d4245;
$light-grey: #808080;
$warm-grey: #707070;
$grey: #888888;
$grey-1: #fff0;
$brownish-grey: #6e6e6e;
$snail: #cacaca;
$haze-grey: #d9d9d9;
$silvera: #eaeaea;
//$silver: #ededed;
$white-3: #f8f8f8;
$white-2: #f1f1f1;
$white: #fff;

$light-indigo: #7d55c7;
$yellow: #ffc700;
$orange: #ff8200;
$pumpkin-orange: #ff7200;
$orange: #ff8200;
$blue: #07a2f5;
$green: #48bb54;
$red: #cd0303;

$border-colour: $haze-grey;

// Typography
$font: 'CircularStd', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

// Layout and Padding
$gutter: 30px;
$half-gutter: $gutter / 2;

// Media query breakpoints
// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap
$screen-sm: 576px; // Small devices (landscape phones, 576px and up)
$screen-md: 768px; // Medium devices (tablets, 768px and up)
$screen-lg: 992px; // Large devices (desktops, 992px and up)
$screen-xlg: 1200px; // Extra large devices (large desktops, 1200px and up)


// Form elements (input, dropdowns, buttons)
$input-height: 40px;
$border-radius: 4px;
$input-height-lg: 56px;

$checkbox-size: 16px;
$checkbox-size-large: 24px;

$input-horizontal-padding: 12px;
$button-vertical-padding: 8px;

$btn-action-size: 36px;

// Animations
@keyframes rotate-forever {
	from {
		transform: rotate(0deg);
		}
	to {
		transform: rotate(360deg);
		}
}

// Rootify variables
:root {
  --font: $font;
}