
.location-container{
    display: flex;
    justify-content: space-between;
    // background: #e0e0e0;
    border: #d9d9d9 1px solid;
    padding: 5px;
}
.locations-display{
    width: 60%;
    display: block;
}
.location-tag{
    border: #753bbd 1px solid;
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
    height: auto;
    background-color: #753bbd;
    color: #fff;
    margin: 5px;
    border-radius: 5px;
    vertical-align: middle;
    float: left;
    cursor: pointer;
}
.location-dropdown{
    width: 30%;
}