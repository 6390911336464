@import './variables.scss';

.kit-details {
    .small-heading {
        padding-bottom: 8px;
        border-bottom: 2px solid $haze-grey;
        margin-bottom: 16px;
    }
    .kit-detail {
        &:not(:first-child) {
            margin-top: 16px;
        }
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
    .btn {
        min-width: 175px;
    }
}

.kit-details-disbaled-input{
    border: none;
    background-color: #f8f8f8;
}
.packing-details-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    
}
.packing-details-item > input{
    width: -webkit-fill-available;    
    max-width: 300px;
}
.packing-details-item > label{
    margin-bottom: 0px;
}
.packing-details-row-error::after {
    content: 'Please fix highlighted issues otherwise your changes will be discarded';
    margin-left: 15px;
    color: red;
    font-size: 10px;
}
.kit-details__form {
    margin-top: 16px;
    .form-control {
        margin-top: 8px;
        margin-bottom: 8px;
    }
}

.kit__packing-list {
    ul {
        margin: 0;
        padding: 0;
    }
    li {
        font-weight: bold;
        list-style: none;
        &:before {
            content: " ";
            margin-right: 12px;
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: $purple;
            position: relative;
            top: -2px;
        }
    }
}

.kit__print-label {
    .kit-label {
        margin-top: 8px;
        margin-bottom: 16px;
    }
}