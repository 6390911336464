@import './variables.scss';

    .mbs-form {
        margin-top: 24px;
        margin-bottom: 24px;
        .icon--tooltip {
            margin-left: 6px;
        }
    }
    
    .mbs-form__table {
        .form-label {
            font-weight: bold;
            height: $input-height;
            display: flex;
            align-items: center;
        }
        .form-label,
        .form-input {
            &:not(:first-child) {
                margin-top: 8px;
            }
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
        .item {
            padding-left: 12px;
            padding-right: 12px;
            &:first-child {
                padding-left: 20px;
            }
            &:last-child {
                padding-right: 20px;
            }
        }
        .item__labels {
            width: 14.78%;
        }
        .item__1analyte {
            width: 14.78%;
            .analyte__mbs-code .form-control:not(:placeholder-shown) {
                font-weight: bold;
            }
        }
        .item__2analyte {
            width: 14.78%;
        }
        .item__3analyte {
            width: 14.78%;
        }
        .item__4analyte {
            width: 14.78%;
        }
        .item__5analyte {
            width: 14.78%;
        }
        .item__actions {
            width: 11.28%;
            text-align: center;
        }
    }
    
