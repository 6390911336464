@import './variables.scss';

$progress-color: #808080;
$active-color: $slate-grey;
$done-color: $purple-lighten-1;

.progress__container {
    margin-top: 32px;
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid $haze-grey;
    padding-left: 50px;
}

.progress__bar {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
}

.progress__item {
    width: 25%;
    position: relative;
    color: $progress-color;
    .inner {
        position: relative;
        z-index: 1;
        background: $white;
        padding: 0 8px;
    }
    &:not(:last-child):after {
        content: " ";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $haze-grey;
        border-radius: 1px;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
    }

    &.progress--active {
        color: $active-color;
        .progress__icon {
            background-color: $active-color;
            border-color: $active-color;
            color: $white;
        }
    }

    &.progress--done {
        color: $done-color;
        &:after {
            background-color: $done-color;
        }
        .progress__icon {
            background-color: $done-color;
            border-color: $done-color;
            color: $white;
        }
    }
}

.progress__icon {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    display: inline-block;
    text-align: center;
    border: 1px solid $progress-color;
    color: $progress-color;
    margin-right: 8px;
    .icon {
        position: relative;
        top: -1px;
        left: 0.5px;
        &.icon--tick {
            left: auto;
            // top: 2px;
        }
    }
}