@import './variables.scss';

.filters__heading {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 2px solid $haze-grey;
    padding-top: 2px;
    padding-bottom: 10px;
}

.filter {
    margin: 20px 0;
    &-label {
        font-weight: bold;
    }
    .DayPickerInput {
        width: 100%;
    }
}

.checkbox-filter {
    label {
        margin: 0 0 8px;
    }
    .status-icon {
        position: relative;
        top: 2px;
    }
}