@import './variables.scss';
.search-container {
    background-color: #f8f8f8;
}
.search-header-container {
    padding: 15px;
    margin-top: 20px;
    display:'flex';
    align-items: 'flex-end' !important;
    background-color: #ffffff;
}
.search-result-container {
    margin-top: 40px;
}
.search-result-row{
    font-weight: 300;
}