@import './variables.scss';

/*
* Main Styles
*/

/* Layout and Padding */
html, 
body {
    font-family: $font;
    color: $dark-grey;
    font-size: 15px;
    line-height: 1.6;
}

.page-heading {
    padding-top: 32px;
    padding-bottom: 32px;
    margin-bottom: 32px;
    background-color: $white-3;
    border-bottom: 1px solid $haze-grey;
    .row {
        align-items: center;
    }
    h1 {
        margin-bottom: 0;
    }
    .btn-container {
        margin: 0;
        padding: 0;
        border: 0;
    }
    .btn {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.section {
    margin-top: 32px;
    margin-bottom: 32px;
}

/* Elements */
table {
    text-align: left;
    width: 100%;
}

td, th {
    padding: 10px;
    background: rgba(255,255,255,0.2);
}

/* Typography */
h1,
h2,
h3,
h4 {
    font-weight: bold;
}
h1 {
    color: $purple;
    font-size: 40px;
    line-height: 1.2;
    &.display-1 {
        font-size: 37px;
        line-height: 1.22;
    }
    &.display-2 {
        font-size: 56px;
        line-height: 1.29;
    }
}
h2 {
    font-size: 32px;
    line-height: 1.25;
}
h3 {
    font-size: 24px;
    line-height: 1.33;
}
h4 {
    font-size: 18px;
    line-height: 1.33;
}

.large {
    font-size: 40px;
    line-height: 1.2;
}

small,
.small {
    font-size: 0.866rem; /* 13px; */
    line-height: 1.54;
}

.small-heading {
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
}

a,
a.link:not([href]) {
    color: $purple;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
        color: $purple-lighten-1;
    }
    &:hover {
        text-decoration: underline;
    }
}

.muted {
    color: $light-grey;
}

.disabled {
    pointer-events: none;
}

:focus {
    outline: $purple-lighten-2 auto 5px;
}

.flex-table {
    .row {
        display: flex;
        flex-wrap: wrap;
        > * {
            flex-grow: 1;
            width: 100%;
            // overflow: hidden;
            // padding: 0.8rem;
            text-align: left;
        }
    }
    .list--heading {
        font-weight: bold;
        font-size: 11px;
        text-transform: uppercase;
    }
}

.flex-table-form {
    .row {
        align-items: center;
    }
    .item {
        padding: 16px;
    }
    .list--heading .item {
        padding-bottom: 4px;
    }
}

/* Form elements (input, dropdowns, buttons) */
@import './global/buttons.scss';

@import './global/form-elements.scss';

/* Icons */
@import './global/icons.scss';

/* Popover */
@import './global/popover.scss';

/* Modal */
@import './global/modal.scss';

/* Alerts */
.alert {
    padding: 16px;
	margin-top: 20px;
	margin-bottom: 20px;
    border-radius: $border-radius;
    p {
        margin-bottom: 8px;
    }
    ul {
        margin-bottom: 0;
        padding-left: 16px;
    }
}

.alert-danger {
	background-color: #fdf3f3;
	border: solid 1px rgba(205, 3, 3, 0.3);
	color: $red;
}

/* Loading overlay */
.loading-overlay {
    width: 100%;
    height: 100%;
    background: rgba($white, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    .spinner__wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    .loading-spinner {
        height: 40px;
        width: 40px;
        border: 3px solid $light-grey;
        border-right-color: transparent;
    }
}

/* Back link */
.page__back {
    margin-bottom: 16px;
    .page__back__link {
        position: relative;
        cursor: pointer;
    }
    .icon--back {
        width: 24px;
        height: 24px;
        position: relative;
        // top: 6px;
    }
    .link {
        color: $purple;
        border-bottom: 1px solid $purple;
    }
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

.date-display {
    text-transform: uppercase;
}

