.location-list-item-header{
    color: #753bbd;
    padding:0;
}
.location_input_container{
    padding: 0;
}
.margin-right-10{
    margin-right: 10px;
}
.location-item-edit-icons{
    padding: 0;
}
.location-item-edit-input{
    width: 90%;
}
.settings-footer{
    width: 100%;
    display: flex;
    justify-content: center;
}
.settings-footer-buttons-container{
    width: 30%;
    display: flex;
    justify-content: space-between;
    margin: 70px;
}
.settings-footer-buttons-container-button{
    width: 40%;
}
.location-settings-container{
    margin-left: 6%;

}
.border-bottom-primary-color{
    // border-bottom: 1px #753bbd solid;
    margin-top: 10px;

}