@import './variables.scss';

$nav-item-horizontal-padding: 24px; // 1.6rem
$nav-item-vertical-padding: 16px; // 1.06rem
$underline: 2px;

nav {
    border-bottom: 1px solid $haze-grey;

    .nav-link {
        padding-top: $nav-item-vertical-padding;
        padding-bottom: $nav-item-vertical-padding - $underline;
        padding-left: 0;
        padding-right: 0;
        border-bottom: $underline solid transparent;
        color: $dark-grey;
        &:hover {
            color: $purple;
        }
    }

    .nav-item {
        padding-left: $nav-item-horizontal-padding;
        padding-right: $nav-item-horizontal-padding;
        &.is-active .nav-link {
            color: $purple;
            border-bottom-color: $purple;
        }
        &:first-child {
            padding-left: 0;
            .nav-link {
                padding-left: 0;
            }
        }
        &:last-child {
            padding-right: 0;
            .nav-link {
                padding-right: 0;
            }
        }
    }
}