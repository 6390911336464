@import './variables.scss';

.analyte-panel-list {
    .item__panel {
        width: 10%;
    }
    .item__analytes {
        width: 60%;
    }
    .item__mbs-code {
        width: 10%;
    }
    .item__status {
        width: 10%;
    }
    .item__actions {
        width: $btn-action-size + $half-gutter;
        display: flex;
        justify-content: flex-end;
    }
}

.panel--live {
    font-size: 10px;
    border-radius: 10px;
    background-color: $green;
    color: $white;
    padding: 2px 14px;
}

.add-edit-panel {

    .panel-name__container {
        align-items: center;
    }
    
    .panel--live {
        font-size: 13px;
        text-transform: uppercase;
        font-weight: bold;
        display: inline-block;
        border-radius: 12px;
        padding-left: 22px;
        padding-right: 22px;
    	margin-top: 18px;
    }

    select.form-control {
        background-position: center right 12px;
    }
}

.add-edit-panel__analytes {
    margin-top: 12px;
    border-top: 1px solid $haze-grey;
    padding-top: 24px;

    .analytes-list {
        margin-top: 16px;
    }

    .analyte-item {
        background-color: $white-3;
        border-radius: $border-radius;
        padding: 12px 16px;
        &:not(:first-child) {
            margin-top: 8px;
        }
        &:not(:last-child) {
            margin-bottom: 8px;
        }
        .analyte--name{
            overflow-wrap: break-word;  
            max-width: 80%; 
        }       
    }
    
    .analyte--remove {
        max-width: calc(200% - 100px);
        text-align: right;
    }
    

    .analyte-input {
        margin-top: 16px;
    }
}

.modal--delete-panel {
    max-width: 540px;
}