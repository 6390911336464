@import './variables.scss';

$cant-find-panel-vert-alignment: 64px;

.episodes__panels {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: $white;
    z-index: 12;
    display: flex;
    flex-direction: column;
    .popover--cant-find-panel {
        top: -$cant-find-panel-vert-alignment;
    }
    .bs-popover-left .arrow,
    .bs-popover-auto[x-placement^="left"] .arrow {
        top: $cant-find-panel-vert-alignment + 2;
        margin: unset;
    }
    .popover__container .link {
        &:hover {
            text-decoration: none;
        }
        &:hover span:first-child {
            text-decoration: underline;
        }
        .icon {
            margin-left: 4px;
        }
    }
}

.episodes__panels__header {
    width: 100%;
    flex: 0 0 auto;
    background-color: $white-3;
    border-bottom: 1px solid $haze-grey;
    position: relative;
    padding-top: 24px;
    padding-bottom: 24px;
    h2 {
        color: $purple;
        text-align: center;
        margin-bottom: 0;
    }
    .close {
        position: absolute;
        width: 32px;
        height: 32px;
        right: 28px;
        top: 0;
        bottom: 0;
        margin: auto;
        opacity: 1;
        &:hover {
            opacity: 0.5;
        }
        span,
        svg {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
}

.episodes__panels__summary {
    width: 100%;
    flex: 0 0 auto;
    .inner {
        padding-top: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $haze-grey;
    }
    h4 {
        margin-bottom: 0;
    }
}

.episodes__panels__table-heading {
    width: 100%;
    flex: 0 0 auto;
    .flex-table {
        margin-top: 4px;
        padding-bottom: 4px;
        &.bordered {
            border-bottom: 1px solid $haze-grey;
        }
    }
}

.episodes__panels__content {
    width: 100%;
    flex: 1 1 auto;
    position: relative;
    overflow-y: auto;
}

.episodes__panels__footer {
    width: 100%;
    flex: 0 0 auto;
}
