@import './variables.scss';

.protocol-summary__container {
    margin-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid $haze-grey;
}

.protocol-summary {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
    li {
        width: 33.33%;
    }
}

.protocol-summary__item {
    font-size: 18px;
    margin-top: 2px;
}