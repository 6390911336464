.form-control {
    height: $input-height;
    border-color: $haze-grey;
    color: $dark-grey;
    &:not(:disabled):hover {
        border-color: $purple;
        color: $dark-grey;
    }
    &:not(:disabled):focus,
    &.focus {
        border-color: $purple;
        box-shadow: 0 0 0 0.1rem rgba($purple-lighten-1, 0.4);
        color: $dark-grey;
    }
    &:disabled {
        background-color: $white-2;
        border-color: $haze-grey;
        color: $light-grey;
    }
    &.has-error {
        border-color: $red;
    }
}

.form-control-lg, .input-group-lg > .form-control, 
.input-group-lg > .input-group-prepend > .input-group-text, 
.input-group-lg > .input-group-append > .input-group-text, 
.input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-append > .btn {
    height: $input-height-lg;
    font-size: 32px;
    padding: 0.375rem 0.75rem;
}

select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../assets/images/chevron-down-dark-grey.svg');
    background-repeat: no-repeat;
    background-position: center right 16px;
    &:disabled {
        background-image: url('../../assets/images/chevron-down-disabled.svg');
    }
}

select.form-control:not([size]):not([multiple]) {
    height: $input-height;
}

.form-control--datepicker {
    background-image: url('../../assets/images/calendar.svg');
    background-repeat: no-repeat;
    background-position: center right 12px;
    padding-right: 36px;
}

.has-error {
    .form-control {
        border-color: $red;
    }
    small {
        color: $red;
        ul {
            padding-left: 1rem;
        }
        a {
            color: inherit;
            text-decoration: underline;
        }
    }
}

.input-group {
    .form-control {
        border-color: $slate-grey;
    }
}

.form-control::-webkit-input-placeholder {
    color: $light-grey;
}

.form-control::-moz-placeholder {
    color: $light-grey;
}

.form-control:-moz-placeholder {
    color: $light-grey;
}

.form-control:-ms-input-placeholder {
    color: $light-grey;
}

/* Checkbox */
.checkbox__container {
    position: relative;
    cursor: pointer;
    &.large {
        width: $checkbox-size-large;
        height: $checkbox-size-large;
    }
}

.form-check {
    &-label {
        font-size: 13px;
        padding-left: $checkbox-size + 9px;
    }
    &-input {
        position: static;
        margin: 0 10px 0 0;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    &-checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: $checkbox-size;
        width: $checkbox-size;
        background-image: url('../../assets/images/checkbox-unchecked.svg');
        .large & {
            width: $checkbox-size-large;
            height: $checkbox-size-large;
            background-size: 100%;
        }
    }
    &-input:checked~.form-check-checkmark {
        background-image: url('../../assets/images/checkbox-checked.svg');
    }
}

/* Radio buttons */
.radio__container {
    position: relative;
    cursor: pointer;
}

.form-radio {
    &-label {
        // font-size: 13px;
        padding-left: $checkbox-size + 9px;
    }
    &-input {
        position: static;
        margin: 0 10px 0 0;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    &-radiomark {
        position: absolute;
        top: 6px;
        left: 0;
        height: $checkbox-size;
        width: $checkbox-size;
        background-image: url('../../assets/images/radio-unchecked.svg');
    }
    &-input:checked~.form-radio-radiomark {
        background-image: url('../../assets/images/radio-checked.svg');
    }
}

.radio-block__container {
    margin-bottom: 16px;
}

.radio-block__item {
    &:not(:first-child) {
        margin-top: 8px;
    }
    &:not(:last-child) {
        margin-bottom: 8px;
    }
    input[type="radio"] {
        opacity: 0;
        width: 0;
        height: 0;
        position: absolute;
        &.large ~ label {
            .form-radio-radiomark {
                width: $checkbox-size-large;
                height: $checkbox-size-large;
                background-size: 100%;
            }
        }
    }
    label {
        display: inline-block;
        width: 100%;
        margin: 0;
        padding: 16px 20px;
        border-radius: $border-radius;
        background-color: $white-3;
        cursor: pointer;
    }
    input[type="radio"]:checked ~ label {
        .form-radio-radiomark {
            background-image: url('../../assets/images/radio-checked.svg');
        }
    }
    .form-radio-radiomark {
        left: auto;
        top: auto;
    }
    .form-radio-label {
        position: relative;
        margin-left: 16px;
    }
}

/* Misc */
.form-group--button {
    margin-top: 24px;
}

.flex-table-form-item {
    background: $white-3;
    border-radius: 4px;
    margin-top: 8px;
    margin-bottom: 8px;
    .form-group {
        margin-bottom: 0;
    }
}

.form-add-container {
    margin-top: 32px;
    margin-bottom: 32px;
    text-align: center;
}

.date-picker__container {
    display: none;
    position: absolute;
    background: $white;
    z-index: 1;
    &.show {
        display: block;
    }
}
label {
    margin-bottom: 4px;
}
.form-group {
    margin-bottom: 20px;
}