@import './variables.scss';

.episodes {
    margin-top: -16px;
}

.episodes__heading {
    margin-top: 16px;
    margin-bottom: 16px;
}

.episodes__summary {
    margin-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $haze-grey;
    h4 {
        margin-bottom: 0;
    }
}

.episodes-item--screening {
    .item {
        padding-top: 24px;
        padding-bottom: 24px;
    }
}

.episodes-form {
    select {
        background-position: center right 10px;
    }

    .period--single-time,
    .period--time-period {
        .label {
            margin-left: 8px;
            margin-right: 8px;
        }
    }

    .period--single-time {
        .input-time {
            width: 88px;
            display: inline-block;
            position: relative;
            padding-left: 0;
            padding-right: 0;
            span {
                width: 4px;
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
            }
            .invisible-input {
                text-align: center;
                width: 50%;
            }
            .item__hour {
                padding-left: 0.375rem;
            }
            .item__minute {
                padding-right: 0.375rem;
            }
        }
    }

    .period--time-period {
        .form-control {
            display: inline-block;
            width: 56px;
        }
    }


    .btn--panels {
        width: 178px;
        .btn__section:first-child {
            width: 26.966%; /* 48px */
        }
        .btn__section:last-child {
            width: 73.034%; /* 130px */
        }
    }
}

.episodes-form__table {
    padding-top: 12px;

    .item:not(:first-child) {
        padding-left: 12px;
    }

    .item:not(:last-child) {
        padding-right: 12px;
    }

    .item__reorder {
        width: 2%;
        @media (max-width: $screen-xlg) {
            width: 2%;
        }
    }

    .item__day {
        width: 12.7272%;
        @media (max-width: $screen-xlg) {
            width: 12.7272%;
        }
    }

    .item__period {
        width: 12.5454%;
    }

    .item__period-details {
        width: 23.2727%;
        @media (max-width: $screen-xlg) {
            width: 28.2727%;
        }
    }

    .item__panels {
        width: 29.2727%;
        @media (max-width: $screen-xlg) {
            width: 23.2727%;
        }
    }

    .item__urgent {
        width: 8.0909%;
        @media (max-width: $screen-xlg) {
            width: 6.0909%;
        }
    }

    .item__actions {
        width: 8%;
        @media (max-width: $screen-xlg) {
            width: 9%;
        }
    }
}

.invisible-input {
    border: 0 none;
    box-shadow: none;
    outline: none;
}

.reorder-container {
    .hide {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }
}