@import './variables.scss';

$dropdown-border-radius: 3px;

.btn-action {
    color: $slate-grey;
    background-color: $white;
    border: 1px solid $haze-grey;
    border-radius: $border-radius;
    width: $btn-action-size;
    height: $btn-action-size;
    padding: 0;
    cursor: pointer;
    display: flex;
	align-items: center;
    justify-content: center;
    margin-top: 0.1rem; // need this for the box shadow to show on the top/bottom
    margin-bottom: 0.1rem;  // need this for the box shadow to show on the top/bottom
    &:hover,
    &:focus {
        color: $purple;
        border-color: $purple;
    }
    &:focus {
        box-shadow: 0 0 0 0.1rem $purple-lighten-2;
        outline: none;
    }
}

.action-dropdown {
    color: $dark-grey;
    top: 100%;
    left: auto;
    right: 0;
    margin-top: -5px;
    margin-right: 15px;
    z-index: 10;
    border-radius: $dropdown-border-radius;
}

.dropdown-item {
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
    color: $dark-grey;
    &:hover,
    &:active,
    &:focus {
        background-color: $white-2;
        color: $dark-grey;
    }
    .icon {
        float: right;
        margin-top: 1px;
    }
    .icon svg {
        stroke: $dark-grey;
    }
}

.submenu {
    display: none;
    position: absolute;
    top: 0;
    right: 100%;
    margin-right: -1px;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $haze-grey;
    border-radius: $dropdown-border-radius;
    &.show {
        display: block;
    }
}