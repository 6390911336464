@import './variables.scss';

.DayPicker-Day {
    border: 1px solid $haze-grey;
    border-radius: 0;
    padding: 8px 10.5px;
}

.DayPicker-Day--outside:not(.DayPicker-Day--hoverRange):not(.DayPicker-Day--selectedRange) {
    border: 0 none;
}

.DayPickerInput-Overlay {
    margin-top: 6px;
    box-shadow: none;
    border: 1px solid $haze-grey;
    border-radius: $border-radius;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $purple;
    border-color: $purple;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: $purple;
    border-color: $purple;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: $light-indigo;
    border-color: $light-indigo;
    color: $white;
}

.DayPicker-Weekday abbr[title] {
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    color: #000;
}

.DayPicker-Caption > div {
    font-weight: bold;
}

.DayPicker-NavButton {
    width: 24px;
    height: 24px;
    background-size: 24px;
}

.DayPicker-NavButton--prev {
    background-image: url('./../assets/images/calendar-left.svg');
    margin-right: 28px;
}

.DayPicker-NavButton--next {
    background-image: url('./../assets/images/calendar-right.svg');
}

.DayPicker-Day--today {
    color: $purple;
}