@import './variables.scss';

.btn-outline-primary{
    border-color: $purple-lighten-1;
    color: $purple-lighten-1;
}
.btn-outline-primary:hover{
    background-color: $purple-lighten-1;
    border-color: $purple-lighten-1;
    
}
.btn-outline-primary:focus, .btn-primary:focus{
    box-shadow: $purple-lighten-1;
}



.screening-form {
    margin-top: 24px;
    margin-bottom: 24px;
    .icon--tooltip {
        margin-left: 6px;
    }
}

.screening-form__table {
    .item__panel {
        width: 60%;
    }
    .item__actions {
        width: 40%;
        text-align: right;
    }
}