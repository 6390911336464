$overlay-bg: rgba(43, 43, 45, 0.5);

.modal__bootstrap {
    max-width: 600px;
    .btn {
        min-width: 145px;
    }
}

.modal-content {
    padding: 24px;
    border: 0 none;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
}

.modal-header,
.modal-body,
.modal-footer {
    padding: 0;
    border: 0 none;
}

.modal-header {
    margin-bottom: 24px;
    .close {
        opacity: 1;
    }
}

.modal-body {
    margin-bottom: 24px;
    p:last-child {
        margin-bottom: 0;
    }
}

.modal-footer {
    justify-content: space-between;
}

.modal--cancel-protocol-overview {
    width: 600px;
    max-width: 100%;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
    background-color: rgba(43, 43, 45, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}