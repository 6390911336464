@import './variables.scss';

.kit-label {
    width: 100%;
    border-radius: $border-radius;
    border: 1px solid $haze-grey;
    padding: 16px;

    .list--item {
        &:not(:first-child) {
            margin-top: 4px;
        }
        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }

    .kit-label__title {
        width: 28%;
    }
    
    .kit-label__value {
        width: 72%;
    }
}

.kit-label__details {
    font-size: 13px;
}
