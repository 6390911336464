$btn-vert-padding: 0.5333rem; // 8px

.btn {
    // height: $input-height;
    padding-top: $btn-vert-padding; // 8px
    padding-bottom: $btn-vert-padding; // 8px
    &-primary {
        border-color: $purple;
        background-color: $purple;
        &:hover {
            border-color: $purple;
            background-color: rgba($purple, 0.85);
            color: $white;
        }
        &:active,
        &:not(:disabled):not(.disabled):active {
            border-color: $purple;
            background-color: $purple;
            color: $white;
        }
        &:focus,
        &:not(:disabled):not(.disabled):active:focus {
            border-color: $purple;
            background-color: $purple;
            color: $white;
            box-shadow: 0 0 0 0.1rem $purple-lighten-2;
        }
        &[disabled] {
            border-color: $purple-lighten-2;
            background-color: $purple-lighten-2;
        }
    }
    &-secondary {
        background-color: $grey-1;
        border-color: $slate-grey;
        color: $slate-grey;
        &:hover {
            background-color: $slate-grey;
            color: $white;
        }
        &:active,
        &:not(:disabled):not(.disabled):active {
            background-color: $slate-grey;
            color: $white;
        }
        &:focus,
        &:not(:disabled):not(.disabled):active:focus {
            background-color: $slate-grey;
            color: $white;
            box-shadow: 0 0 0 0.1rem rgba($slate-grey, 0.4);
        }
        &:disabled {
            background-color: rgba($slate-grey, 0.4);
            border-color: lighten($slate-grey, 60%);
        }
    }
}

.btn--with-sections {
    padding: 0;
}

.btn__section {
    padding: $btn-vert-padding 0.75rem;
    display: inline-block;
}

.btn__section + .btn__section {
    border-left: 1px solid;
}

.btn-container {
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid $haze-grey;
    .btn + .btn {
        margin-left: 16px;
    }
}

.form-btn-container {
    .btn {
        min-width: 140px;
    }
}