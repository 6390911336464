@import './variables.scss';

.unauthenticated-layout {
    display: flex;
    padding-top: 32px; /* 2.133rem; */
    padding-bottom: 32px; /* 2.133rem; */
    &__inner {
        width: 100%;
        max-width: 350px;
    }

    h2 {
        color: $purple;
        margin-bottom: 32px;
    }

    &__subheading {
        border-bottom: 1px solid $haze-grey;
        margin-bottom: 20px;
    }

    h3 {
        margin-bottom: 16px;
    }
}