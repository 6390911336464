@import './variables.scss';

.ki-container{
    background: $white-3;
    border-radius: 4px;
    width: 100%;
    padding-bottom: 10px;
}
.ki-heading{
    width: 100%;
    border-bottom: 1px solid $haze-grey;
    padding-top: 8px;
    margin-bottom: 15px;
}

.ki-information-box{
    background: $white;
    margin-top: 8px;
    margin-left: 8px;
    width: 80%;
    padding-bottom: 1px;
}
.ki-item-container{
    margin: unset;
}
.ki-item-col{
    padding: unset;
}
.ki-modal-header{
    background-color: #753bbd;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.ki-modal-footer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
}
.ki-modal-body{
    height: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.ki-modal-title{
    padding-top: 6px;
    color: #fff;
}
.ki-modal-content{
    background: #fff;
    padding: 0px;
    border: 0 none;
    border-radius: 5px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    
}
.ki-modal-row{
    width: 100%;
    justify-content: space-evenly;
    
}
.ki-overlay{
    z-index: 12;
    
}