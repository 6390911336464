@import './variables.scss';

.pagination {
    margin-top: $gutter;
    justify-content: flex-end;
    .page-link {
        padding: 0.67rem 1.05rem;
    }
   
}

.page-item.active .page-link {
    background-color: $purple;
    border-color: $purple;
    pointer-events:none;
   }
.page-item.disabled .page-link{
    color: $grey;
}