@import './variables.scss';

.filter-table {
    .row {
        display: flex;
        flex-wrap: wrap;
        > * {
            flex-grow: 1;
            width: 100%;
            overflow: hidden;
            // padding: 0.8rem;
            text-align: left;
        }
    }
    .list--heading {
        font-weight: bold;
        font-size: 11px;
        text-transform: uppercase;
        border-bottom: 2px solid $haze-grey;
        padding-top: 2px;
        padding-bottom: 10px;
    }
    .list--item {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $haze-grey;
        align-items: center;
        position: relative;
    }
    .list--no-results {
        padding-top: 16px;
        padding-bottom: 16px;
        color: $warm-grey;
        .item {
            text-align: center;
        }
    }
    .item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .item:not(:first-child) {
        padding-left: $half-gutter / 2;
    }
    .item:not(:last-child) {
        padding-right: $half-gutter / 2;
    }
    .item:first-child {
        padding-left: $half-gutter;
    }
    .item:last-child {
        padding-right: $half-gutter;
    }
}