@import './variables.scss';

.kits-management__nav {
    margin-top: -32px;
    margin-bottom: 32px;
}

.kits__title {
    margin-bottom: 32px;
}

.kits-list {
    .list--item {
        cursor: pointer;
        padding-top: 16px;
        padding-bottom: 16px;
        &:hover {
            background-color: $purple-opacity-40;
            .item__kit-code,
            .item__actions {
                color: $purple;
            }
        }
    }
    .item__kit-code {
        width: 6%;
    }
    .item__protocol {
        width: 10%;
    }
    .item__cohort {
        width: 18%;
    }
    .item__episode {
        width: 12%;
    }
    .item__numberused {
        width: 7%;
    }
    .item__packingrequired {
        width: 12%;
    }
    .item__packed-required {
        width: 17%;
        display: flex;
        align-items: center;
        .packed-value,
        .packed-progress-bar {
            width: 40%;
        }
        .packing--incomplete {
            color: $pumpkin-orange;
        }
    }
    .item__actions {
        width: 3%;
    }
    .item__actions {
        width: $btn-action-size + $half-gutter;
        display: flex;
        justify-content: flex-end;
    }
    
}

