@import './variables.scss';

$checkbox-size: 24px;

.cohorts.hide {
    display: none;
}

.cohorts-form {

    .form-control {
        display: inline-block;
    }

    .form-control--cohorts-small {
        @media (min-width: $screen-xlg + 1) {
            max-width: 64px;
        }
    }

    .btn--episodes {
        .btn__section:first-child {
            width: 48px;
        }
        .btn__section:nth-child(2) {
            padding-left: 20px;
            padding-right: 20px;
            @media (max-width: $screen-xlg) {
                padding-left: 12px;
                padding-right: 12px;
            }
        }
    }

    .checkbox__container {
        width: $checkbox-size;
        height: $checkbox-size;
    }
    .form-check-checkmark {
        width: $checkbox-size;
        height: $checkbox-size;
        background-size: 100%;
    }

    .action-dropdown {
        margin-top: -15px;
        min-width: 203px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14);
    }

    .DayPickerInput-Overlay {
        left: auto;
        right: 0;
    }
}

.cohorts-form__table {
    padding-top: 12px;
    max-width: 1200px;
    width: 1200px;
    .list--heading {
        align-items: flex-start;
        font-size: 10px;
    }

    .item:not(:first-child) {
        @media (max-width: $screen-xlg) {
            padding-left: 8px;
        }
    }

    .item:not(:last-child) {
        @media (max-width: $screen-xlg) {
            padding-right: 8px;
        }
    }

    .item__cohort-no {
        width: 15%;
        @media (max-width: $screen-xlg) {
            width: 30%;
        }
    }
    .item__target-no-of-on-study-participants {
        width: 7%;
    }
    .item__target-no-of-day-minus-one-participants {
        width: 7%;
    }
    .screening-required {
        width: 5%;
    }
    .item__first-dosing-date {
        width: 12%;
        padding-right: 1px;
        padding-left: 1px;
    }
    .item__episodes {
        width: 16%;
    }
    .item__required {
        width: 4%;
        @media (max-width: $screen-xlg) {
            text-align: center;
        }
    }
    .item__actions {
        width: 48px;
    }
    
    .list--item {
        .item__episodes {
            .form-control {
                text-align: center;
            }
        }

        .item__actions {
            position: relative;
            display: flex;
            justify-content: flex-end;
        }
    }
}