@import './variables.scss';

.progress {
    height: 8px;
    border-radius: $border-radius;
    background-color: $silvera;
}

.progress-bar {
    background-color: $pumpkin-orange;
    .progress--done & {
        background-color: $purple;
    }
}
.progress__item.progress--done
{
    color: $purple;
        .progress__icon{
        background-color: $purple;
        border-color: $purple;
    
    }
}



